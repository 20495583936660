<template>
	<div class="menu" :class="{ opened: mobileStat }">
		<div class="menu-logo">
			<p>MQ Service</p>
			<img src="../../images/icon-doublearrow.svg" alt="doublearrow" />
		</div>
		<div class="menu-bar">
			<ul @click="mobileStat = !mobileStat">
				<li>
					<router-link to="/">
						<img src="../../images/menu-1.svg" alt="menu" />
						<p>CallBack</p>
					</router-link>
				</li>
				<li>
					<router-link to="/endpoint">
						<img src="../../images/menu-2.svg" alt="menu" />
						<p>EndPoint</p>
					</router-link>
				</li>
				<li>
					<router-link to="/binded-list">
						<img src="../../images/menu-3.svg" alt="menu" />
						<p>Bind</p>
					</router-link>
				</li>
			</ul>
		</div>
		<div class="mobile-toggler" @click="mobileStat = !mobileStat"></div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			mobileStat: false,
		};
	},
};
</script>
<style lang="scss" scooped>
.menu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	&-logo {
		margin-bottom: 20px;
		border-bottom: 1px solid #f1f3f9;
		// background: lightsalmon;
		cursor: pointer;
		transition: all 0.3s;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;

		&:hover {
			background: #f1f3f9;
		}

		p {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			margin: 0;
			padding: 0;
			color: #000000;
		}
	}

	&-bar {
		ul {
			display: flex;
			flex-direction: column;
			// background: lightpink;

			li {
				width: 100%;
				height: 60px;

				a {
					cursor: pointer;
					transition: all 0.2s ease;
					// background: lightblue;
					padding: 10px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					padding: 0 30px;
					width: 100%;
					height: 100%;
					position: relative;
					color: black;

					&::after {
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						width: 5px;
						content: "";
						background: #007bff;
						display: none;
					}

					img {
						width: 25px;
						height: 25px;
						object-fit: content;
						margin-right: 10px;
						transition: all 0.3;
						filter: grayscale(1);
					}

					p {
						text-decoration: none;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 140%;
						color: #a1a1ab;
					}

					&:hover {
						background: #007bff;
						color: #ffffff;

						p {
							color: #ffffff;
						}

						img {
							filter: brightness(0) invert(1);
						}
					}

					&.router-link-exact-active {
						pointer-events: none;

						img {
							filter: revert;
						}

						p {
							color: #007bff;
						}

						&::after {
							display: flex;
						}
					}
				}
			}
		}
	}

	.mobile-toggler {
		display: none;
	}
}
</style>
