export const State = Object.freeze({
  ACTIVE: { value: "ACTIVE", title: "Active" },
  INACTIVE: { value: "INACTIVE", title: "Inactive" }
});

export const HttpMethods = Object.freeze({
  GET: { value: "GET", title: "GET" },
  POST: { value: "POST", title: "POST" },
  PATCH: { value: "PATCH", title: "PATCH" },
  UPDATE: { value: "UPDATE", title: "UPDATE" },
  DELETE: { value: "DELETE", title: "DELETE" }
});
