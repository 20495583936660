<template>
	<div class="layout">
		<Header />
		<div class="layout-wrapper">
			<div class="layout-menu">
				<left-bar />
			</div>
			<div class="layout-content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script>
import Header from "../header";
import LeftBar from "../left-bar";

export default {
	components: { Header, LeftBar },
};
</script>
<style lang="scss">
.layout {
	&-wrapper {
		display: flex;
	}
	&-menu {
		min-width: 350px;
		border-right: 1px solid #f1f3f9;
		// background: lightcoral;
	}
	&-content {
		width: 100%;
		display: flex;
		padding-left: 50px;
		flex-direction: column;
		// background: lightgreen;
	}
}
</style>
