import Vue from "vue";
import App from "./App.vue";
import Keycloak from "keycloak-js";
import NProgress from "vue-nprogress";
import router from "./routes";
import "./styles/main.scss";
import "./styles/media_query.scss";

Vue.use(NProgress);
const nprogress = new NProgress();

let initOptions = {
	url: "https://id.platon.uz/",
	realm: "developers",
	clientId: "mq-service",
	onLoad: "login-required",
};

let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;
Vue.prototype.$url = "";

keycloak
	.init({ onLoad: initOptions.onLoad })
	.then((auth) => {
		if (!auth) {
			window.location.reload();
		} else {
			new Vue({
				nprogress,
				router,
				el: "#app",
				render: (h) => h(App, { props: { keycloak: keycloak } }),
			});
		}

		setInterval(() => {
			keycloak
				.updateToken(70)
				.then((refreshed) => {
					if (refreshed) {
						// Vue.$log.info("Token refreshed" + refreshed);
					} else {
						// Vue.$log.warn(
						//   "Token not refreshed, valid for " +
						//     Math.round(
						//       keycloak.tokenParsed.exp +
						//         keycloak.timeSkew -
						//         new Date().getTime() / 1000
						//     ) +
						//     " seconds"
						// );
					}
				})
				.catch((e) => {
					console.error(e);
				});
		}, 6000);
	})
	.catch((e) => {
		console.error(e);
	});
