<template>
	<div class="header">
		<div class="header-logo">
			<img src="../../images/logo.svg" alt="logo" />
		</div>
		<div class="header-list">
			<btn class="primary" @click.native="$keycloak.logout()">
				<template v-slot:image> <img src="../../images/icon-logout.svg" alt="" /> </template>
				<template v-slot:text> <span>Log Out</span> </template>
			</btn>
		</div>
	</div>
</template>
<script>
import Button from "../button";
export default {
	components: { btn: Button },
};
</script>
<style lang="scss" scoped>
.header {
	display: flex;
	align-items: center;
	height: 70px;
	padding: 0 20px;
	justify-content: space-between;
	// background: lightblue;

	&-logo {
		// background: lightcoral;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		font-weight: 600;
		width: 150px;
		height: 40px;

		img {
			display: flex;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
</style>
