<template>
	<div class="btn">
		<slot name="image"></slot>
		<slot name="text"></slot>
	</div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.btn {
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 5px;
	min-height: 45px;
	min-width: 130px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease;

	img {
		width: 20px;
		height: 20px;
		display: flex;
		object-fit: contain;
		margin-right: 5px;
		transition: 0.3s;
		filter: brightness(0) invert(1);
	}

	&:hover {
		img {
			filter: revert;
		}
	}
}

.primary {
	color: #fff;
	border: 1px solid #007bff;
	background-color: #007bff;

	&:hover {
		color: #007bff;
		background-color: #fff;
	}
}
.primary-outline {
	color: #007bff;
	background-color: #fff;
	border: 1px solid #007bff;
	&:hover {
		color: #fff;
		background-color: #007bff;
		border: 1px solid #007bff;
	}
}
.secondary {
	color: #fff;
	border: 1px solid #6c757d;
	background-color: #6c757d;
	&:hover {
		color: #6c757d;
		background-color: #fff;
	}
}
.secondary-outline {
	color: #6c757d;
	background-color: #fff;
	border: 1px solid #6c757d;
	&:hover {
		color: #fff;
		background-color: #6c757d;
		border: 1px solid #6c757d;
	}
}
.success {
	color: #fff;
	background-color: #28a745;
	border: 1px solid #28a745;
	&:hover {
		color: #28a745;
		background-color: #fff;
	}
}
.success-outline {
	color: #28a745;
	background-color: #fff;
	border: 1px solid #28a745;
	&:hover {
		color: #fff;
		background-color: #28a745;
		border: 1px solid #28a745;
	}
}
.danger {
	color: #fff;
	background-color: #dc3545;
	border: 1px solid #dc3545;
	&:hover {
		color: #dc3545;
		background-color: #fff;
	}
}
.danger-outline {
	color: #dc3545;
	background-color: #fff;
	border: 1px solid #dc3545;
	&:hover {
		color: #fff;
		background-color: #dc3545;
		border: 1px solid #dc3545;
	}
}
.warning {
	color: #fff;
	background-color: #ffc107;
	border: 1px solid #ffc107;
	&:hover {
		color: #ffc107;
		background-color: #fff;
	}
}
.warning-outline {
	color: #ffc107;
	background-color: #fff;
	border: 1px solid #ffc107;
	&:hover {
		color: #fff;
		background-color: #ffc107;
		border: 1px solid #ffc107;
	}
}
.info {
	color: #fff;
	background-color: #17a2b8;
	border: 1px solid #17a2b8;
	&:hover {
		color: #17a2b8;
		background-color: #fff;
	}
}
.info-outline {
	color: #17a2b8;
	background-color: #fff;
	border: 1px solid #17a2b8;
	&:hover {
		color: #fff;
		background-color: #17a2b8;
		border: 1px solid #17a2b8;
	}
}
.link {
	color: #007bff;
}
.link-outline {
	color: #007bff;
}

@media (max-width: 576px) {
	.btn {
		padding: 6px 10px;
		min-width: 100px;
		font-size: 14px;
	}

	.btn.danger {
		font-size: 12px;
		padding: 5px 10px;
		min-width: 60px;
		min-height: 30px;
	}
}
</style>
