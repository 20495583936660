<template>
	<div class="app">
		<mq-layout>
			<router-view />
		</mq-layout>
	</div>
</template>

<script>
import MqLayout from "./components/layout";

export default {
	components: { MqLayout },
	data() {
		return {
			msg: "Welcome to Your Secured Vue.js App with Keycloak",
		};
	},
};
</script>

<style></style>
