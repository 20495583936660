export default {
  data() {
    return {
      list: [],
      endpoint: [],
      search: "",
      callback: [],
      selected: {},
      isLoading: false
    };
  },

  methods: {
    async getList(url = "", name = "list") {
      this.isLoading = false;
      this.$nprogress.start();
      await fetch(this.$url + `${url}`)
        .then(data => data.json())
        .then(response => {
          this[name] = response;
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.isLoading = true;
          this.$nprogress.done();
        });
    },

    filteredList(type = "queue") {
      return this.list.filter(item => item[type].id.includes(this.search));
    },

    handleSelect(list = {}) {
      this.$route.query.type = "update";
      this.selected = Object.assign(list, { mode: "Update" });
    },

    createdData(data) {
      this.list.push(data);
      this.selected = {};
    },

    deletedData(id = "") {
      this.$nprogress.start();
      this.list = this.list.filter(item => item.id !== id);
      this.selected = {};
      this.$nprogress.done();
    },

    isEmpty() {
      for (var _ in this.selected) return true;
      return false;
    }
  }
};
