<template>
	<div class="callback">
		<div class="callback-cap">
			<div class="action">
				<h2>Endpoint</h2>
				<btn
					@click.native="
						handleCreate();
						mobileStatus = true;
					"
					class="primary"
				>
					<template v-slot:image> <img src="../../images/icon-create.svg" alt="" /> </template>
					<template v-slot:text> <span>Create</span> </template>
				</btn>
			</div>
			<div class="input">
				<input placeholder="search" v-model="search" />
			</div>
		</div>
		<div class="callback-wrapper" v-if="isLoading">
			<div class="callback-list">
				<div class="callback-actived" v-for="el of filteredList('exchange')" :key="el.id" :class="{ 'acive-table': isEmpty() && el.id === selected.id }">
					<p
						class="clicker"
						@click="
							handleSelect(el);
							mobileStatus = true;
						"
					>
						{{ el.exchange.id }}
					</p>
					<!-- <span :class="isActive(el)" /> -->
					<div class="checkboxbtn">
						<label>
							<input type="checkbox" @click="setCheckStatus(el)" :checked="isChecked(el)" />
							<span>
								<i></i>
							</span>
						</label>
					</div>
				</div>
			</div>
			<div class="callback-form" :class="{ opened: mobileStatus }">
				<callback-form v-if="isEmpty()" :selected="selected" @create="createdData" @deleted="deletedData" />
				<div v-else>Forma hosil qilish uchun listdan tanlang yoki yangi yarating</div>
				<div class="mobile-btn" @click="mobileStatus = false"><span></span><span></span></div>
			</div>
		</div>
	</div>
</template>
<script>
import CallbackForm from "./form.vue";
import GetterMixins from "../../mixins/getter";
import Button from "../../components/button";
export default {
	components: { CallbackForm, btn: Button },

	mixins: [GetterMixins],

	data() {
		return {
			mobileStatus: false,
		};
	},

	created() {
		this.getList("/api/endpoint/list");
	},

	methods: {
		isActive(el) {
			return el.state.toLowerCase() == "active" ? "active" : "inactive";
		},
		isChecked(el) {
			if (el.state.toLowerCase() == "active") {
				return true;
			} else {
				return false;
			}
		},
		setCheckStatus(el) {
			// let status = el.state.toLowerCase();
			// let id = el.id;
			// let action = status == "active" ? "disable" : "enable";
			// this.setCheckApi(action, id);
		},

		async setCheckApi(type, id) {
			const rawResponse = await fetch(`/api/callback/${type}?id=${id}`, {
				method: "PATCH",
			});
			this.getList("/api/callback/list");
		},

		handleCreate() {
			this.selected = {
				path: "",
				exchange: "",
				method: "GET",
				mode: "Create",
				jsFunction: "",
				state: "INACTIVE",
			};
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../styles/callback-body.scss";
</style>
