<template>
	<div class="form">
		<h1 class="title">{{ selected.mode }}</h1>
		<div class="form-select">
			<select v-model="method">
				<option v-for="el of httpMethods" :key="el.value" :value="el.value">
					{{ el.title }}
				</option>
			</select>
			<select v-model="state">
				<option v-for="el of states" :key="el.value" :value="el.value">
					{{ el.title }}
				</option>
			</select>
		</div>
		<div class="form-input">
			<input type="text" v-model="path" placeholder="url" />
			<input type="text" v-model="exchange" placeholder="queue" />
		</div>
		<div class="form-editor flex column">
			jsFunctions:
			<code-editor class="atome_one_dark" height="300px" v-model="jsFunction"></code-editor>
			<div class="form-saver"></div>
		</div>
		<div class="form-actions flex">
			<btn @click.native="handleSave('/api/endpoint/create', 'exchange', 'path')" class="primary">
				<template v-slot:text>Save</template>
			</btn>
			<div class="flex" v-if="$route.query.type === 'update'">
				<btn @click.native="handleDelete('/api/endpoint/delete')" class="danger">
					<template v-slot:text>Delete</template>
				</btn>
			</div>
		</div>
	</div>
</template>
<script>
import CodeEditor from "simple-code-editor";
import ActionMixin from "../../mixins/actions";
import Button from "../../components/button";
import { State, HttpMethods } from "../../const";

export default {
	mixins: [ActionMixin],
	props: {
		selected: {
			type: Object,
			default: function () {
				return {
					id: "",
					exchange: "",
					jsFunction: "",
					path: this.$url,
					state: State.ACTIVE.value,
					method: HttpMethods.GET.value,
				};
			},
		},
	},
	components: {
		CodeEditor,
		btn: Button,
	},
	data() {
		return {
			id: this.selected.id,
			path: this.selected.path,
			exchange: this.selected.exchange,
			state: this.selected.state,
			method: this.selected.method,
			jsFunction: this.selected.jsFunction || "",
		};
	},
	computed: {
		states() {
			return State;
		},
		httpMethods() {
			return HttpMethods;
		},
	},

	watch: {
		selected(value) {
			this.id = value.id;
			this.path = value.path;
			this.state = value.state;
			this.method = value.method;
			this.exchange = value.exchange.id;
			this.jsFunction = value.jsFunction || "";
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../styles/callback-form.scss";
</style>
