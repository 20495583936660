<template>
	<div class="bind">
		<div class="bind-cap flex">
			<div class="action">
				<h2>Binded list</h2>
				<router-link to="/bind-create">
					<btn class="primary">
						<template v-slot:image> <img src="../../images/icon-create.svg" alt="" /> </template>
						<template v-slot:text> <span>Create</span> </template>
					</btn>
				</router-link>
			</div>

			<div class="input">
				<input placeholder="Search" v-model="search" />
			</div>
		</div>
		<div>
			<div>
				<div class="bind-list">
					<table>
						<tr>
							<th>Exchange</th>
							<th>Queue</th>
							<th>Prefix</th>
							<th>Actions</th>
						</tr>
						<tr v-for="el of list" :key="el.id" @click="handleSelect(el)">
							<td>{{ el.exchange.id }}</td>
							<td>{{ el.queue.id }}</td>
							<td>{{ el.prefix }}</td>
							<td>
								<btn class="danger" @click.native="deleteBinded(el)">
									<template v-slot:text>Unbind</template>
								</btn>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import GetterMixins from "../../mixins/getter";
import Button from "../../components/button";
export default {
	mixins: [GetterMixins],

	components: { btn: Button },

	created() {
		this.getList("/api/bind/list");
	},

	methods: {
		async deleteBinded(el) {
			const headers = {
				method: "POST",
				headers: {
					"Content-Type": "application/json;charset=utf-8",
				},
				body: JSON.stringify({
					queue: el.queue.id,
					exchange: el.exchange.id,
				}),
			};
			await fetch(this.$url + "/api/unbind", headers)
				.then((res) => res.json())
				.then(() => {
					console.log("Successfully deleted");
					this.deletedData(el.id);
				})
				.catch((err) => console.log(err));
		},
	},
};
</script>
<style lang="scss">
table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

td,
th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

tr:nth-child(even) {
	background-color: #dddddd;
}

td {
	span {
		display: inline-block;
	}
}

.bind {
	&-cap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;

		.action {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		h2 {
			margin: 0;
			padding: 0;
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 130%;
			margin-right: 30px;
			min-width: 150px;
			color: #000000;
		}

		.input {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 50px;
			width: 280px;
			position: relative;
			// background: #ffffff;
			border: 1px solid #f1f3f9;
			border-radius: 4px;
			margin-right: 30px;
			overflow: hidden;

			input {
				width: 100%;
				height: 100%;
				border: 0;
				outline: 0;
				padding-left: 55px;

				&::placeholder {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 120%;
					color: #a1a1ab;
				}
			}

			&::after {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				bottom: 0;
				background: url(../../images/icon-search.svg) no-repeat center center;
				width: 50px;
				flex-shrink: 0;
			}
		}
	}
	&-list {
		margin-top: 40px;
		overflow: auto;
		max-height: 75vh;
	}
}

@media (max-width: 576px) {
	td,
	th {
		font-size: 12px;
		padding: 5px;
	}

	.bind-cap {
		.input {
			display: none;
		}
	}
}
</style>
