export default {
  data() {
    return {};
  },

  methods: {
    handleDelete: async function (endpoint) {
      this.$nprogress.start();
      await fetch(`${this.$url}${endpoint}?id=${this.id || ""}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => this.$emit("deleted", data.id))
        .catch((err) => console.log(err))
        .finally(() => this.$nprogress.done());
    },
    handleSave: async function (endpoint, type, linkType) {
      this.$nprogress.start();
      await fetch(this.$url + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          [type]: this[type],
          [linkType]: this[linkType],
          state: this.state,
          method: this.method,
          jsFunction: this.jsFunction || null,
          jsOnSuccess: this.jsOnSuccess || null,
        }),
      })
        .then((res) => res.json())
        .then((data) => this.$emit("create", data))
        .catch((err) => console.log(err))
        .finally(() => this.$nprogress.done());
    },
  },
};
