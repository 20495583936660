<template>
	<div class="bind-create">
		<div class="callback-cap bind-create--header flex align-center">
			<div class="action">
				<h2>Bind create</h2>
				<btn v-if="isActiveBinding" class="primary" @click.native="bindElements">
					<template v-slot:text>Bind</template>
				</btn>
			</div>
		</div>
		<div class="bind-create--list flex">
			<div class="bind-create--el flex column">
				<div class="bind-create--el-item" v-for="back of callback" :key="back.id" @click="selectedBack(back)" :class="{ selected: selectedCallback.id === back.id }">
					<p>
						<span>queue-id</span>: {{ back.queue.id }}
						<span v-if="selectedCallback.id === back.id"> ✔ </span>
					</p>
				</div>
			</div>
			<div class="bind-create--el flex column">
				<div class="bind-create--el-item" v-for="point of endpoint" :key="point.id" @click="selectedPoint(point)" :class="{ selected: selectedEndpoint.id === point.id }">
					<p>
						<span>exchange-id</span>: {{ point.exchange.id }}
						<span v-if="selectedEndpoint.id === point.id"> ✔ </span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GetterMixins from "../../mixins/getter";
import Button from "../../components/button";

export default {
	mixins: [GetterMixins],

	components: { btn: Button },

	data() {
		return {
			selectedCallback: {
				id: "",
				queue: "",
			},
			selectedEndpoint: {
				id: "",
				exchange: "",
			},
		};
	},

	methods: {
		selectedBack(value) {
			this.selectedCallback = {
				id: value.id,
				queue: value.queue.id,
			};
		},

		selectedPoint(value) {
			this.selectedEndpoint = {
				id: value.id,
				exchange: value.exchange.id,
			};
		},

		async bindElements() {
			const headers = {
				method: "POST",
				headers: {
					"Content-Type": "application/json;charset=utf-8",
				},
				body: JSON.stringify({
					queue: this.selectedCallback.queue,
					exchange: this.selectedEndpoint.exchange,
				}),
			};
			await fetch(this.$url + "/api/bind", headers)
				.then((res) => res.json())
				.then(() => {
					this.selectedCallback = { id: "", queue: "" };
					this.selectedEndpoint = { id: "", exchange: "" };
				})
				.catch((err) => console.log(err));
		},
	},

	computed: {
		isActiveBinding() {
			return this.selectedCallback.id !== "" && this.selectedEndpoint.id !== "";
		},
	},

	created() {
		this.getList("/api/callback/list", "callback");
		this.getList("/api/endpoint/list", "endpoint");
	},
};
</script>

<style lang="scss">
@import "../../styles/callback-body.scss";

.bind-create {
	&--list {
		width: 100%;
		height: 300px;
		margin-top: 30px;
	}
	&--el {
		gap: 15px;
		margin-right: 5%;

		&-item {
			padding: 10px 20px;
			cursor: pointer;
			border-radius: 6px;
			border: 1px solid #e7e7e7;
			transition: all 0.2s ease;
			font-family: "Inter";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			color: #000000;

			&:hover {
				box-shadow: 0 10px 5px #ccc;
			}
		}
	}
}
.selected {
	box-shadow: 0 10px 5px #ccc;
}
</style>
