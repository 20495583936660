import Vue from "vue";
import VueRouter from "vue-router";

import CallBack from "./views/callback";
import EndPoint from "./views/endpoint";
import BindedList from "./views/bindedList";
import BindCreate from "./views/bindedCreate";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_ROUTER_BASE_URL,

  routes: [
    {
      name: "callBack",
      path: "/",
      component: CallBack
    },
    {
      name: "endpoint",
      path: "/endpoint",
      component: EndPoint
    },
    {
      name: "binded-list",
      path: "/binded-list",
      component: BindedList
    },
    {
      name: "bind-create",
      path: "/bind-create",
      component: BindCreate
    }
  ]
});

export default router;
